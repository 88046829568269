<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item :to="{ name: 'brandcatelist' }">品牌分类设置</el-breadcrumb-item>
        <el-breadcrumb-item>{{ infoForm.id ? '编辑品牌分类' : '添加品牌分类' }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation-nav">
        <el-button type="primary" @click="goBackPage" icon="arrow-left">返回列表</el-button>
      </div>
    </div>
    <div class="content-main">
      <div class="form-table-box">
        <el-form ref="infoForm" :rules="infoRules" :model="infoForm" label-width="120px">
          <el-form-item label="品牌分类名称" prop="name">
            <el-input v-model="infoForm.name"></el-input>
          </el-form-item>

          <el-form-item label="排序">
            <el-input-number v-model="infoForm.sort" :min="1" :max="1000"></el-input-number>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
            <el-button @click="goBackPage">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/config/api';
import ElFormItem from 'element-ui/packages/form/src/form-item.vue';

export default {
  data() {
    return {
      root: '',

      uploaderHeader: {
        'I-WanBo-Token': localStorage.getItem('token') || ''
      },

      infoForm: {
        id: '',
        name: '',
        sort: 100
        // is_show: true,
      },
      infoRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      },
      picData: {
        token: ''
      },
      url: ''
    };
  },
  methods: {
    goBackPage() {
      this.$router.go(-1);
    },
    onSubmitInfo() {
      this.$refs['infoForm'].validate((valid) => {
        if (valid) {
          this.axios.post('brand/storeBrandCate', this.infoForm).then((response) => {
            if (response.data.errno === 0) {
              this.$message({
                type: 'success',
                message: '保存成功'
              });
              this.$router.go(-1);
            } else {
              this.$message({
                type: 'error',
                message: '保存失败'
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    getInfo() {
      if (this.infoForm.id <= 0) {
        return false;
      }
      //加载分类详情
      let that = this;
      this.axios
        .get('brand/brandCateinfo', {
          params: {
            id: that.infoForm.id
          }
        })
        .then((response) => {
          let resInfo = response.data.data;
          that.infoForm = resInfo;
        });
    }
  },
  components: { ElFormItem },
  mounted() {
    this.infoForm.id = this.$route.query.id || 0;
    this.getInfo();
    this.root = api.rootUrl;
  }
};
</script>

<style scoped>
.image-uploader {
  height: 105px;
}

.image-uploader .el-upload {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.image-uploader .el-upload:hover {
  border-color: #20a0ff;
}

.image-uploader .image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  min-width: 105px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-show {
  background-color: #f8f8f8;
  min-width: 105px;
  height: 105px;
  display: block;
}
</style>
